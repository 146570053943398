import { css } from "lit"

export const brandonGrotesque = css`
  font-family: "Brandon Grotesque", sans-serif;
  font-size: var(--brandon-grotesque-font-size-offset);
  color: var(--black-87);
`

export const brandonGrotesqueText = css`
  font-family: "Brandon Text", sans-serif;
  font-size: var(--brandon-grotesque-font-size-offset);
  color: var(--black-87);
`

export const elevated = css`
  box-shadow: 0 calc(0.05rem * var(--elevation, 1)) calc(0.15rem * var(--elevation, 1)) 0 var(--elevation-shadow-color, rgb(0 0 0 / 10%));
`

/**
 * Returns a CSSResult that applies the given content to all pseudo-classes.
 *
 * Pseudo-classes include:
 * - `:hover`
 * - `:active`
 * - `:focus`
 * - `:focus-within`
 * - `:visited`
 * @param {import('lit').CSSResult} content - The content to apply to all pseudo-classes.
 * @returns {import('lit').CSSResult} The CSSResult that applies the content to all pseudo-classes.
 */
export function interactivePseudoClasses(content) {
  return css`
    &:hover,
    &:active,
    &:focus,
    &:focus-within,
    &:visited {
      ${content}
    }
  `
}

export const Breakpoints = {
  mobile: "mobile",
  desktop: "desktop",
}

const mobileBreakpoint = 1024

/**
 * Returns a CSSResult that applies the content to the given breakpoint.
 * @param {Breakpoints} breakpoint - The breakpoint to apply the content to.
 * @param {import('lit').CSSResult} content - The content to apply.
 * @returns {import('lit').CSSResult} The CSSResult that applies the content to the given breakpoint.
 */
export function atBreakpoint(breakpoint, content) {
  switch (breakpoint) {
    case Breakpoints.mobile:
      return css`
        @media (max-width: ${mobileBreakpoint - 1}px) {
          ${content}
        }
      `
    case Breakpoints.desktop:
      return css`
        @media (min-width: ${mobileBreakpoint}px) {
          ${content}
        }
      `
    default:
      throw new Error(`Unknown breakpoint: ${breakpoint}`)
  }
}

export const underline = css`
  text-decoration: underline;
  text-decoration-color: var(--interactive-color);
  text-decoration-thickness: 0.1em;
  text-underline-offset: 0.2em;
`

export const interactiveText = css`
  cursor: pointer;
  color: var(--interactive-color);
  outline: none;

  ${interactivePseudoClasses(underline)}
`

export const interactiveObject = css`
  cursor: pointer;
  color: inherit;
  transition: opacity 150ms ease-in-out;

  &:focus {
    outline: var(--interactive-color) solid medium;
    outline-offset: 0;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover:not(:disabled, [disabled],:active,:focus) {
    outline-offset: 0;
    filter: brightness(1.03);
  }
`
export const sentenceCase = css`
  text-transform: none;

  &::first-letter {
    text-transform: uppercase;
  }
`

export const menuArrow = css`
  display: inline-block;
  content: "";
  height: 0.49em;
  aspect-ratio: 1;
  margin: 0.5em;
  box-sizing: border-box;
  transform: rotate(45deg);
  transition: transform 150ms ease-in-out;
  border-bottom-width: 0.1em;
  border-bottom-style: solid;
  border-right-width: 0.1em;
  border-right-style: solid;
  vertical-align: text-top;
  position: relative;
  top: -0.1em;
`

export const hyphenate = css`
  hyphens: auto;
  hyphenate-limit-chars: 8 3 4;
`
